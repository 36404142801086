export const INIT_CURRENT_TRACK = "INIT_CURRENT_TRACK";
export const PLAY_CURRENT_TRACK = "PLAY_CURRENT_TRACK";
export const PAUSE_CURRENT_TRACK = "PAUSE_CURRENT_TRACK";
export const UPDATE_CURRENT_TIME = "UPDATE_CURRENT_TIME";
export const PREV_TRACK = "PREV_TRACK";
export const NEXT_TRACK = "NEXT_TRACK";
export const MUTE_TRACK = "MUTE_TRACK";
export const LOOP_TRACK = "LOOP_TRACK";
export const CHANGE_CURRENT_TIME = "CHANGE_CURRENT_TIME";
export const SELECT_CURRENT_TRACK = "SELECT_CURRENT_TRACK";
export const UPDATE_VOLUME = "UPDATE_VOLUME";