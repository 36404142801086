export const INIT_MY_LIBRARY = 'INIT_MY_LIBRARY'
export const POST_PLAYLIST = 'POST_PLAYLIST'
export const POST_PRIVATE_TRACK = 'POST_PRIVATE_TRACK'
export const PUT_TRACK_TO_PLAYLIST = 'PUT_TRACK_TO_PLAYLIST'

export const LIKE_TRACK = 'LIKE_TRACK'
export const LIKE_ARTIST = 'LIKE_ARTIST'
export const LIKE_ALBUM = 'LIKE_ALBUM'
export const LIKE_PLAYLIST = 'LIKE_PLAYLIST'

export const DISLIKE_TRACK = 'DISLIKE_TRACK'
export const DISLIKE_ARTIST = 'DISLIKE_ARTIST'
export const DISLIKE_ALBUM = 'DISLIKE_ALBUM'
export const DISLIKE_PLAYLIST = 'DISLIKE_PLAYLIST'